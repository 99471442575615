@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root {
  --primary-color: #3a5a6e;
  --secondary-color: #7ca3a6;
  --accent-color: #a5c9ca;
  --background-color: #f0f4f5;
  --text-color: #2c3e50;
  --sidebar-bg: #dbe4e6;
  --sidebar-button-bg: #7ca3a6;
  --header-bg: #a5c9ca;
  --button-color: #7ca3a6;
  --top-bar-bg: #2c3e50;
  --default-border-radius: 4px;
  --default-margin: 10px;
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: var(--top-bar-bg);
  color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  border-radius: var(--default-border-radius);
  margin: var(--default-margin);
}

.home-button {
  margin-left: auto; /* Push the button to the far right */
  margin-right: 50px;
  background-color: #007bff; /* Example button color */
  color: white; /* Example text color */
  border: none;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 5px; /* Example border radius */
}

.home-button:hover {
  background-color: #0056b3; /* Example hover color */
}

.container {
  display: flex;
  height: calc(100vh - 48px); /* Adjust if needed based on the height of the top-bar */
  margin: var(--default-margin);
}

.sidebar {
  position: fixed;
  top: 50px; /* Adjust if needed based on the height of the top-bar */
  left: 0;
  width: 180px; /* Adjust based on your layout */
  height: calc(100vh - 48px); /* Adjust if needed based on the height of the top-bar */
  background-color: var(--sidebar-bg);
  overflow: hidden; /* Ensure sidebar does not scroll */
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1); /* Optional: Add a shadow for better visual separation */  
  margin: var(--default-margin);
  border-radius: var(--default-border-radius);

}

.sidebar button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--sidebar-button-bg);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: var(--default-border-radius);
}

.content {
  margin-left: 200px; /* Same width as sidebar */
  padding-top: 10px;
  overflow-y:  clip;
  height: calc(100vh - 48px); /* Adjust if needed based on the height of the top-bar */
  width: calc(100% - 200px); /* Ensure the content takes the remaining width */
  background-color: #fff; /* Optional: Content background color */
}

.content2 {
  margin-left: 200px; /* Same width as sidebar */
  padding-top: 40px;
  height: calc(100vh - 48px); /* Adjust if needed based on the height of the top-bar */
  width: calc(100% - 200px); /* Ensure the content takes the remaining width */
  background-color: #fff; /* Optional: Content background color */
}

.portal-content {
  padding-top: 30px;
  overflow-y: auto;
  height: calc(100vh - 48px); /* Adjust if needed based on the height of the top-bar */
  width: 100%; /* Ensure the content takes the remaining width */
  background-color: #fff; /* Optional: Content background color */
}

.welcome {
  justify-content: 'center';
  align-items: 'center';
  padding: 25px;
}

.sidebar a {
  text-decoration: none;
  color: inherit;
}

.sidebar a.active button {
  background-color: rgb(82, 103, 96);
  color: white; 
} 

.button {
  background-color: var(--button-color);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  border-radius: var(--default-border-radius);
}

.button:hover {
  filter: brightness(1.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-color: #ffcc00;
}

.button:disabled {
  background-color: grey;
  color: lightgrey;
}

.button:hover:disabled {
  background-color: grey; /* Ensures background remains grey on hover */
  color: lightgrey; /* Ensures text remains lightgrey on hover */
  box-shadow: none;
}

.spaced-button {
  margin-right: 5px;
  margin-bottom: 5px;
}

.button-secondary {
  background-color: #007bff;
}

.button-tertiary {
  background-color: grey;
}

.button-danger {
  background-color: crimson;
}

.button-success {
  background-color: green;
}

.property-container {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  word-wrap: break-word;
  text-align: left;
  vertical-align: top;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.4;
}

table {
  border-collapse: collapse;
  border: none;
  width: 100%;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid black;
  padding: 5px;
  text-align: left;
  vertical-align: top;
}

.header img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.header {
  background-color: var(--header-bg);
  color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: var(--default-border-radius);
  margin: var(--default-margin);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.object-list-header {
  background-color: var(--header-bg);
  color: white;
  display: flex;
  align-items: center;
  border-radius: var(--default-border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.review-portal-header {
  position: fixed;
  top: 40px; /* Adjust if needed based on the height of the top-bar */
  left: 0;
  width: 100%; /* Adjust based on your layout */
  height: 100px; /* Adjust if needed based on the height of the top-bar */
  background-color: skyblue; /* Optional: Sidebar background color */
  color: white;
  overflow: hidden; /* Ensure sidebar does not scroll */
  padding: 10px;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1); /* Optional: Add a shadow for better visual separation */
}

.main-content {
  padding: 10px 20px 20px 20px;
}

.pre-format {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.pre-format2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 1.3;
  padding: 10px;
  background-color: #f4f4f4;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.highlight {
  background-color: #fff9c4; /* Soft yellow background for light attention */
  padding: 15px;
  border-radius: 8px;
  font-size: 1.1em; /* Slightly increase font size */
  line-height: 1.5; /* Improve readability */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for emphasis */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  animation: pulse 3s infinite;
}

.focus-area {
  background-color: #fcfef5; /* Soft yellow background for light attention */
  padding: 15px;
  border-radius: 8px;
  font-size: 0.8 em; /* Slightly reduce font size */
  line-height: 1.3; /* Improve readability */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for emphasis */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  animation: pulse 3s infinite;
}

.content-display {
  margin-bottom: 10px;
}

.copy-button {
  margin-top: 10px;
}

/* Target the main container of the Markdown content */
.markdown-content {
  line-height: 1.2;
  background-color: white;
  padding-right: 20;
  padding-left: 10;
  padding-bottom: 5;
  padding-top: 1;
}

/* Reduce spacing between paragraphs */
.markdown-content p {
  margin: 0;
}

/* Reduce spacing above and below headings */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 0;
  margin-bottom: 0;
}

/* Adjust spacing for lists */
.markdown-content ul,
.markdown-content ol {
  margin-top: 0;
  margin-bottom: 0;
}

/* Reduce spacing between list items */
.markdown-content li {
  margin-bottom: 0;
}


/* import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css" */

/* Mandatory CSS required by the Data Grid */
@import "~ag-grid-community/styles/ag-grid.css";

/* Optional Theme applied to the Data Grid */
@import "~ag-grid-community/styles/ag-theme-quartz.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

.ag-theme-quartz-red {
  /* Changes the color of the grid text */
    --ag-foreground-color: rgb(25, 25, 25);
    /* Changes the color of the grid background */
    --ag-background-color: #ffe2db;
    /* Changes the header color of the top row */
    --ag-header-background-color: #ffc5b6;
    /* Changes the hover color of the row*/
    --ag-row-hover-color: rgb(216, 226, 255);
}
